<template>
  <div class="mt-1">
    <b-overlay :show="initialLoad" rounded="sm">
      <b-row>
        <b-col md="12" class="mb-2">
          <b-button variant="primary" class="mr-2" @click.prevent="handleAddPort">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add New Port</span>
          </b-button>
        </b-col>
        <b-col md="12">
          <my-table
            title=""
            :rows="ports"
            :columns="columns"
            :pagination="pagination"
            :is-loading="isLoading"
            :has-filter="true"
          >
            <template #filter>
              <VueSelect
                v-model="selectedPortType"
                class="select-w"
                label="text"
                placeholder="Select port type"
                :options="types"
                :clearable="false"
                @input="handleSetSelectedType"
              />
            </template>

            <template v-slot="{ props }">
              <span v-if="props.column.field === 'date_added'">
                {{ $helpers.formatDate(props.row.date_added) }}
              </span>
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <b-dropdown-item @click.prevent="handleEditMarket(props.row)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="handleDeletePort(props.row)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </my-table>
        </b-col>
      </b-row>
    </b-overlay>
    <AddEditPortModal
      :is-show="showPortModal"
      :form-data="selectedPort"
      :is-edit="isEdit"
      :title="title"
      @reset-modal="handleResetAddPort"
      @add-success="handlePortSuccess"
    />
  </div>
</template>

<script>
import AddEditPortModal from '@/components/Fleet/AddEditPortModal.vue'
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'
import VueSelect from 'vue-select'

export default {
  name: 'MyPorts',
  components: {
    MyTable,
    AddEditPortModal,
    VueSelect,
  },
  data() {
    return {
      ports: [],
      columns: [
        {
          label: 'ID',
          field: 'id',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-nowrap text-left',
          type: 'number',
        },
        {
          label: 'Port Name',
          field: 'name',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Type',
          field: 'type',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        /* {
          label: 'Unit number',
          field: 'unit_number',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Address 1',
          field: 'address1',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Address 2',
          field: 'address2',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Postal Code',
          field: 'postal_code',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'State',
          field: 'state',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        }, */
        {
          label: 'Country',
          field: 'country',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
          sortable: false,
        },
      ],
      isSubmitting: false,
      isLoading: false,
      isFirstLoading: true,
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      initialLoad: false,
      showPortModal: false,
      isEdit: false,
      selectedPort: {},
      title: '',
      types: [
        { text: 'All Port', value: 'All' },
        { text: 'Airport', value: 'Air' },
        { text: 'Seaport', value: 'Sea' },
      ],
      selectedPortType: { text: 'All Port', value: 'All' },
      port_type: 'All',
    }
  },
  created() {
    this.fetchPorts()
  },
  methods: {
    async fetchPorts() {
      try {
        if (this.isFirstLoading) {
          this.isFirstLoading = false
          this.isLoading = true
        }
        const response = await axios.get(`/port?type=${this.port_type}`)
        if (response.status === 200) {
          this.ports = response.data.data
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        throw new Error('Something went wrong!', error)
      }
    },
    handleAddPort() {
      this.title = 'Add New Port'
      this.isEdit = false
      this.showPortModal = true
    },
    handleEditMarket(row) {
      this.title = 'Update Port'
      this.isEdit = true
      this.selectedPort = {
        id: row.id,
        name: row.name,
        type: row.type,
        // unit_number: row.unit_number,
        // address1: row.address1,
        // address2: row.address2,
        // state: row.state,
        country: row.country,
        // postal_code: row.postal_code,
      }
      this.showPortModal = true
    },
    handleResetAddPort() {
      this.showPortModal = false
    },
    handlePortSuccess(message) {
      this.fetchPorts()
      this.$swal({
        icon: 'success',
        title: message.title,
        text: message.text,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    handleDeletePort(port) {
      this.$swal({
        title: 'Are you sure?',
        text: `Do you really want to delete "${port.port_name}"? This process cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line arrow-parens
      }).then(async (result) => {
        if (result.value) {
          const response = await axios.delete(`/port/${port.id}`)
          if (response.status === 200) {
            this.$swal({
              icon: 'success',
              title: 'Deleted',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            // eslint-disable-next-line arrow-parens
            this.ports = this.ports.filter((item) => item.id !== port.id)
          }
        }
      })
    },

    async handleSetSelectedType(value) {
      this.port_type = value.value
      await this.fetchPorts()
    },
  },
}
</script>
<style lang="scss" scoped>
.select-w {
  width: 150px;
}
</style>
