<template>
  <validation-provider v-slot="{ errors }" :vid="vidName" name="Type">
    <v-select
      v-model="selectedPortType"
      :options="types"
      :clearable="false"
      label="text"
      :reduce="(portType) => portType.value"
      placeholder="Select a port type"
      :disabled="!isEditable"
      @input="handleSetSelectedType"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'MySelectCountries',
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    portType: {
      type: String,
      default: '',
    },
    vidName: {
      type: String,
      default: 'type',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      types: [
        { text: 'Airport', value: 'Air' },
        { text: 'Seaport', value: 'Sea' },
      ],
      selectedPortType: null,
    }
  },
  created() {
    this.selectedPortType = this.portType
  },
  methods: {
    handleSetSelectedType() {
      this.$emit('set-selected-type', this.selectedPortType)
    },
  },
}
</script>
