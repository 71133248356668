<template>
  <b-modal
    id="new-port-modal"
    ref="new-port-modal"
    v-model="show"
    :title="title"
    size="md"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <ValidationObserver ref="newPortForm" v-slot="{ passes }">
      <form @submit.prevent="passes(handleAddNewPort)">
        <PortForm :form="form" />
        <hr />
        <b-button variant="primary" class="float-right mt-1" type="submit" :disabled="isLoading">
          <b-spinner v-if="isLoading" class="mr-50 p-0" small />
          <feather-icon v-else icon="SaveIcon" class="mr-50" />
          {{ isEdit ? 'Update Port' : 'Add New Port' }}
        </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import axios from '@axios'
import PortForm from '@/components/Fleet/PortForm.vue'

export default {
  name: 'AddEditPortModal',
  components: {
    ValidationObserver,
    PortForm,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Add New Port',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      form: {},
      isLoading: false,
    }
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
    formData(value) {
      this.form = value
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.form = {}
      this.$emit('reset-modal')
    },
    async handleAddNewPort() {
      try {
        this.isLoading = true
        const response = this.isEdit
          ? await axios.patch(`/port/${this.form.id}`, this.form)
          : await axios.post('/port', this.form)
        if (response.status === 422) {
          this.$refs.newPortForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.handleHiddenModal()
        this.$emit('add-success', {
          title: this.isEdit ? 'Updated Port' : 'New Port Added',
          text: response.data.message,
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
