<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group label="Type">
          <MySelectPortType
            :vid-name="'type'"
            :port-type="form.type"
            @set-selected-type="handleSetSelectedType"
          />
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="Port Name">
          <validation-provider v-slot="{ errors }" vid="name" name="name">
            <b-form-input v-model="form.name" placeholder="Enter Port name" />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- <b-col md="12">
        <b-form-group label="Unit number">
          <validation-provider v-slot="{ errors }" vid="unit_number" name="unit_number">
            <b-form-input v-model="form.unit_number" placeholder="Enter unit number" />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="Address line 1">
          <validation-provider v-slot="{ errors }" vid="address1" name="address1">
            <b-form-input v-model="form.address1" placeholder="Enter Address line 1" />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="Address line 2">
          <validation-provider v-slot="{ errors }" vid="address2" name="address2">
            <b-form-input v-model="form.address2" placeholder="Enter Address line 2" />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="Postal Code">
          <validation-provider v-slot="{ errors }" vid="postal_code" name="postal_code">
            <b-form-input
              v-model="form.postal_code"
              type="number"
              placeholder="Enter Postal code"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group label="State">
          <validation-provider v-slot="{ errors }" vid="state" name="state">
            <b-form-input v-model="form.state" placeholder="Enter State" />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col> -->

      <b-col md="12">
        <b-form-group label="Country">
          <validation-provider v-slot="{ errors }" vid="country" name="country">
            <b-form-input v-model="form.country" placeholder="Enter Country" />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import MySelectPortType from '@/components/Fleet/MySelectPortType.vue'

export default {
  name: 'PortForm',
  components: {
    ValidationProvider,
    MySelectPortType,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleSetSelectedType(value) {
      this.form.type = value
    },
  },
}
</script>
